<template>
  <div>
    <!-- 还需要一个系统配置的功能 配置全局的 logo 名称  图片-->
    <!--  系统集合配置： 默认首页 页面标题 标题点击跳转 页面logo 页面logo跳转 隐藏菜单 -->
    <el-drawer
      :title="title"
      :visible.sync="drawer"
      :before-close="handleClose"
      :max-height="maxHeight"
      size="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-form-item label="菜单来源" prop="productSource">
            <el-select
              v-model="ruleForm.productSource"
              placeholder="请选择"
              :disabled="editShow"
              @change="getSystem"
            >
              <el-option
                v-for="item in systemFromList"
                :key="item.uniqueCode"
                :label="item.name"
                :value="item.uniqueCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="类型" prop="type">
            <el-radio-group
              v-model="ruleForm.type"
              @change="getTypeButton"
              :disabled="editShow"
            >
              <el-radio label="1" :disabled="this.pageType == 2">目录</el-radio>
              <el-radio label="2">菜单</el-radio>
              <el-radio label="3">按钮</el-radio>
              <el-radio label="4">接口</el-radio>
              <el-radio label="5">权限</el-radio>
              <el-radio label="6" disabled>静态资源</el-radio>
              <el-radio label="7" disabled>自定义</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
        </el-row>

        <el-row>
          <el-col :span="10">
            <el-form-item label="图标" prop="icon">
              <el-input v-model="ruleForm.icon"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="排序" prop="sort">
              <el-input-number v-model="ruleForm.sort" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="ruleForm.remark"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="状态" prop="status">
              <el-radio-group v-model="ruleForm.status">
                <el-radio label="1">正常</el-radio>
                <el-radio label="2">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="this.ruleForm.type == 1 ||this.ruleForm.type == 2 ">
            <el-form-item label="是否公共" prop="isCommon">
              <el-switch
                v-model="ruleForm.isCommon"
                active-color="#ff4949"
                inactive-color="#13ce66"
                active-text="否"
                inactive-text="是"
                :active-value="2"
                :inactive-value="1"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-form-item label="配置">
            <el-tabs
              v-model="activeName"
              type="border-card"
              @tab-click="handleClick"
            >
              <el-tab-pane label="详细配置" name="first">
                <el-row v-if="this.ruleForm.type == 2">
                  <el-form-item
                    label="上级菜单"
                    prop="parentCode"
                    label-position="left"
                  >
                    <el-cascader
                      :disabled="editShow"
                      :change-on-select="true"
                      :popper-append-to-body="false"
                      size="medium"
                      clearable
                      v-model="ruleForm.parentCodeList"
                      :options="menuOptionList"
                      :props="{
                        value: 'code',
                        label: 'name',
                        checkStrictly: true,
                      }"
                      filterable
                      @change="handleChangeParent"
                      ref="cascader"
                    ></el-cascader>
                  </el-form-item>
                </el-row>

                <el-row v-if="this.ruleForm.type == 2">
                  <el-form-item label="路径" prop="path">
                    <el-input
                      v-model="ruleForm.path"
                      width="30px"
                      :disabled="editShow"
                      v-if="!editShow"
                    >
                      <template slot="prepend">{{ this.codeSign }}</template>
                    </el-input>
                    <span v-else>{{ ruleForm.path }}</span>
                  </el-form-item>
                </el-row>
                <el-row v-if="this.ruleForm.type == 1">
                  <el-form-item label="路径" prop="path">
                    <el-input v-model="ruleForm.path" width="30px"> </el-input>
                  </el-form-item>
                </el-row>

                <el-row v-if="this.ruleForm.type == 1">
                  <el-form-item label="是一级菜单" prop="isMenu">
                    <el-switch
                      v-model="ruleForm.isMenu"
                      active-color="#ff4949"
                      inactive-color="#13ce66"
                      active-text="否"
                      inactive-text="是"
                      :active-value="2"
                      :inactive-value="1"
                    ></el-switch>
                  </el-form-item>
                </el-row>

                <el-row v-if="this.ruleForm.type == 2">
                  <el-form-item
                    label="组件路径"
                    prop="component"
                    label-position="left"
                  >
                    <el-input v-model="ruleForm.component" style="width: 80%" />
                  </el-form-item>
                </el-row>

                <el-row
                  v-if="this.ruleForm.type == 2 || this.ruleForm.type == 1"
                >
                  <el-col :span="14">
                    <el-form-item label="位置" prop="position">
                      <el-radio-group v-model="ruleForm.position">
                        <el-radio label="left">左侧</el-radio>
                        <el-radio label="top">顶部</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="14">
                    <el-form-item label="打开方式" prop="openType">
                      <el-radio-group v-model="ruleForm.openType">
                        <el-radio :label="1">页面</el-radio>
                        <el-radio :label="2">弹框</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row
                  :gutter="24"
                  v-if="this.ruleForm.type == 3 || this.ruleForm.type == 4"
                >
                  <el-form-item label="数据标识" prop="dataSign" label-width="20%">
                    <el-tooltip content="用户业务标志使用" placement="top">
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                    <el-input
                      v-model="ruleForm.dataSign"
                      style="width: 80%"
                      :disabled="editShow"
                    >
                    </el-input>
                  </el-form-item>
                </el-row>
                <!-- 按钮 -->
                <el-row :gutter="24" v-if="this.ruleForm.type == 3">
                  <el-form-item label="二级类型  " prop="twoType">
                    <el-select
                      v-model="ruleForm.twoType"
                      placeholder="请选择"
                      @change="changTwoTypeButton"
                      style="width: 80%"
                    >
                      <el-option
                        v-for="item in twoTypeList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.key"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
                <el-row
                  :gutter="24"
                  v-if="
                    this.ruleForm.type == 3 &&
                    this.ruleForm.twoType != 'commonBnTypeInit'
                  "
                >
                  <el-form-item label="样式类型" prop="cssType">
                    <el-select v-model="ruleForm.cssType" placeholder="请选择">
                      <el-option
                        v-for="item in commonBnCssTypeList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.key"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
                <el-row :gutter="24" v-if="this.ruleForm.type == 3">
                  <el-form-item label="样式值" prop="cssValue">
                    <el-input v-model="ruleForm.cssValue" style="width: 80%" />
                  </el-form-item>
                </el-row>
                <el-row :gutter="24" v-if="this.ruleForm.type == 3">
                  <el-form-item label="事件" prop="bindEvent">
                    <el-input v-model="ruleForm.bindEvent" style="width: 80%" />
                  </el-form-item>
                </el-row>
                <el-row :gutter="24"  v-if="this.ruleForm.type == 4">
                  <el-form-item label="直接调用" prop="isDirect"  label-width="20%">
                    <el-radio-group v-model="ruleForm.isDirect">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
                <!--  接口 -->
                <el-row v-if="this.ruleForm.type == 4&this.ruleForm.isDirect==2">
                  <el-form-item label="服务层api域名" prop="serviceApiRealmName" label-width="20%">
                    <el-input v-model="ruleForm.serviceApiRealmName"></el-input>
                  </el-form-item>
                </el-row>
                <el-row v-if="this.ruleForm.type == 4&this.ruleForm.isDirect==2">
                  <el-form-item label="服务层api路径" prop="serviceApiPath" label-width="20%">
                    <el-input v-model="ruleForm.serviceApiPath"></el-input>
                  </el-form-item>
                </el-row>
                <el-row v-if="this.ruleForm.type == 4&this.ruleForm.isDirect==2" >
                  <el-form-item label="服务层api方式" prop="serviceApiMethod" label-width="20%">
                    <el-select
                      v-model="ruleForm.serviceApiMethod"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in apiMethodList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.key"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-row>


                <el-row v-if="this.ruleForm.type == 4">
                  <el-form-item label="api域名" prop="apiRealmName" label-width="20%">
                    <el-input v-model="ruleForm.apiRealmName"></el-input>
                  </el-form-item>
                </el-row>
                <el-row v-if="this.ruleForm.type == 4">
                  <el-form-item label="api路径" prop="apiPath" label-width="20%">
                    <el-input v-model="ruleForm.apiPath"></el-input>
                  </el-form-item>
                </el-row>
                <el-row v-if="this.ruleForm.type == 4" >
                  <el-form-item label="api方式" prop="apiMethod" label-width="20%">
                    <el-select
                      v-model="ruleForm.apiMethod"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in apiMethodList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.key"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-row>

                <el-row v-if="this.ruleForm.type == 4">
                  <el-form-item label="api分类" prop="apiType" label-width="20%">
                    <el-select v-model="ruleForm.apiType" placeholder="请选择">
                      <el-option
                        v-for="item in apiTypeList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.key"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
                <el-row
                  v-if="this.ruleForm.type == 4 || this.ruleForm.type == 3"
                >
                  <el-form-item label="请求参数" prop="typeParam" label-width="20%">
                    <el-input v-model="ruleForm.typeParam"         placeholder="输入请求参数:占位符用${}代替 列如: userName=${userName}&key='自己的key'"></el-input>
                  </el-form-item>
                </el-row>
                <el-row
                  v-if="this.ruleForm.type == 4 || this.ruleForm.type == 3"
                >
                  <el-form-item label="数据来源" prop="dataSources" label-width="20%">
                    <el-input v-model="ruleForm.dataSources"></el-input>
                  </el-form-item>
                </el-row>
                <el-row
                  v-if="this.ruleForm.type == 3 || this.ruleForm.type == 4"
                >
                  <!-- 多选 -->
                  <el-form-item label="绑定资源" prop="resources" label-width="20%">
                    <el-cascader
                      size="medium"
                      clearable
                      v-model="ruleForm.resourcesList"
                      :options="resourcesList"
                      :props="resourcesProps"
                      @change="handleChange"
                      ref="cascader"
                      v-if="!editShow"
                      filterable
                    ></el-cascader>
                    <span v-else>{{ this.ruleForm.resourcesName }}</span>
                  </el-form-item>
                </el-row>

                <el-row v-if="this.ruleForm.type == 4">
                  <el-form-item label="返回结构" prop="returnParam" label-width="20%">
                    <el-input
                      type="textarea"
                      autosize
                      placeholder="请输入内容"
                      v-model="ruleForm.returnParam"
                    >
                    </el-input>
                  </el-form-item>
                </el-row>

                <!--  权限-->
                <el-row v-if="this.ruleForm.type == 5">
                  <el-form-item label="权限标识" prop="perms">
                    <el-input v-model="ruleForm.perms" />
                  </el-form-item>
                </el-row>
                <el-row v-if="this.ruleForm.type == 5">
                  <el-form-item label="授权策略" prop="permsTactics">
                    <el-radio-group v-model="ruleForm.permsTactics">
                      <el-radio :label="1">可读</el-radio>
                      <el-radio :label="2">可写</el-radio>
                      <el-radio :label="3">读写</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
              </el-tab-pane>
              <el-tab-pane label="高级配置" name="fourth">
                <el-row v-if="this.ruleForm.type == 3">
                  <el-form-item
                    label="权限"
                    prop="permsId"
                    label-position="left"
                  >
                    <el-select
                      v-model="ruleForm.permsId"
                      placeholder="请选择"
                      :disabled="editShow"
                      @change="getPermsButton"
                    >
                      <el-option
                        v-for="item in permsList"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="高级状态" prop="seniorStatus">
                    <el-radio-group v-model="ruleForm.seniorStatus">
                      <el-radio :label="1">正常</el-radio>
                      <el-radio :label="2">隐藏</el-radio>
                      <el-radio :label="3">禁用</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-row>

                <el-row>
                  <el-col :span="12">
                    <el-form-item label="是否刷新" prop="isRefresh">
                      <el-switch
                        v-model="ruleForm.isRefresh"
                        active-color="#ff4949"
                        inactive-color="#13ce66"
                        active-text="否"
                        inactive-text="是"
                        :active-value="2"
                        :inactive-value="1"
                      ></el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="是否缓存" prop="noCache">
                      <el-switch
                        v-model="ruleForm.noCache"
                        active-color="#ff4949"
                        inactive-color="#13ce66"
                        active-text="否"
                        inactive-text="是"
                        :active-value="2"
                        :inactive-value="1"
                      ></el-switch>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="是否登陆" prop="isLogin">
                      <el-switch
                        v-model="ruleForm.isLogin"
                        active-color="#ff4949"
                        inactive-color="#13ce66"
                        active-text="否"
                        inactive-text="是"
                        :active-value="2"
                        :inactive-value="1"
                      ></el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="是否校验" prop="isCheck">
                      <el-switch
                        v-model="ruleForm.isCheck"
                        active-color="#ff4949"
                        inactive-color="#13ce66"
                        active-text="否"
                        inactive-text="是"
                        :active-value="2"
                        :inactive-value="1"
                      ></el-switch>
                    </el-form-item>
                  </el-col>
                </el-row>


                <el-row>
                  <el-col :span="12">
                    <el-form-item label="是否外链" prop="isLink">
                      <el-switch
                        v-model="ruleForm.isLink"
                        active-color="#ff4949"
                        inactive-color="#13ce66"
                        active-text="否"
                        inactive-text="是"
                        :active-value="2"
                        :inactive-value="1"
                      ></el-switch>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row v-if="this.ruleForm.isLink == 1">
                  <el-form-item label="外链地址" prop="link">
                    <el-input
                      v-model="ruleForm.link"
                      style="width: 80%"
                    ></el-input>
                  </el-form-item>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </el-row>
      </el-form>
      <div class="demo-drawer__footer">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
        <el-button @click="cancelForm()">取消</el-button>
        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  treeMenuShapeListV1Api,
  addMenu,
  editMenu,
  menuShapeListApi,
  selectById,
} from "@/api/system/systemCommon/menu.js";

export default {
  data() {
    return {
      drawer: false,
      activeName: "first",
      editShow: false,
      ruleForm: {
        status: "1",
        isLink: 2,
        isCheck:2,
        seniorStatus: 1,
        isRefresh: 2,
        noCache: 2,
        isMenu: 2,
        isCommon: 2,
        isDirect:1
      },
      rules: {
    
      },
      maxHeight: 400, // 设置max-height为窗口高度的一部分
      systemFromList: [],
      menuOptionList: [],
      codeSign: null,
      productSourcePrent: null,
      apiMethodList: [],
      apiTypeList: [],
      twoTypeList: [],
      commonBnCssTypeList: [],
      resourcesList: [],
      permsList: [],
      type: 1,
      pageType: 1,
      title: "",
      resourcesProps: {
        value: "code",
        label: "name",
      },
      ifProps: {
        value: "code",
        label: "name",
      },
      buttonProps: {
        value: "code",
        label: "buttonMensName",
      },
    };
  },
  mounted() {
    this.maxHeight = window.innerHeight * 0.8; // 设置max-height为窗口高度的80%
  },
  watch: {
    codeSign(newValue, oldValue) {
      console.log(newValue, oldValue);
    },
  },
  methods: {
    handleClick(tab, event) {},
    show(row, type, pageType) {
      this.drawer = true;
      this.type = type;
      this.pageType = pageType;
      if (pageType == 2) {
        this.parentCode = row.parentsCode;
      }
      this.getSystemList();
      this.getEnumButtonList();
      if (type == 2) {
        this.editShow = true;
        this.getTypeButton(row.type);
        this.selectByIdButton(row);
        this.title = "编辑";
      } else {
        this.title = "新增";
        this.$set(this.ruleForm, "type", "2");
      }
    },
    selectByIdButton(row) {
      selectById(row.id).then((res) => {
        this.ruleForm = res.result;
        this.ruleForm.type = row.type.toString();
      });
      this.$set(this.ruleForm, "type", row.type.toString());
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.cancelForm();
        })
        .catch((_) => {});
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type == 1) {
            if (this.ruleForm.type == 2) {
              if (
                this.ruleForm.parentCodeList != null &&
                this.ruleForm.parentCodeList.length != 0
              ) {
                this.ruleForm.parentCode = this.ruleForm.parentCodeList[0];
              }
              this.ruleForm.path = this.codeSign + this.ruleForm.path;
            }
            if(this.ruleForm.type == 3||this.ruleForm.type == 4){
              console.log(this.ruleForm.resourcesList)
               if(this.ruleForm.resourcesList ==undefined||this.ruleForm.resourcesList.length==0){
                return this.$message.error("需要绑定一个资源");
               }
            }

            addMenu(this.ruleForm).then((res) => {
              this.cancelForm();
            });
          } else {
            editMenu(this.ruleForm).then((res) => {
              this.cancelForm();
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.drawer = false;
      this.editShow = false;
      if (this.pageType == 2) {
        this.$parent.menuV1ListButton(this.parentCode, 2);
      } else {
        this.$parent.questionnaireId = null;
        this.$parent.queryMenuFrom.code = null;
        this.$parent.menuListButton();
      }
      this.ruleForm = {
        name: "",
        code: "",
        parentCode: "-1",
        isHide: 0,
        type: 2,
        status: "1",
        isLink: 2,
        isCheck:2,
        seniorStatus: 1,
        isRefresh: 2,
        noCache: 2,
        isMenu: 2,
        isCommon:2,
        isDirect:1
      };
    },
    getSystemList() {
      this.systemFromList = this.$parent.systemList;
      if (this.systemFromList.length != 0) {
        this.$set(
          this.ruleForm,
          "productSource",
          this.systemFromList[0].uniqueCode
        );
        this.getSystem(this.systemFromList[0].uniqueCode);
      }
    },
    getSystem(val) {
      if (this.ruleForm.type != 1) {
        this.productSourcePrent = val;
        this.codeSign = "/" + val + "-";
        this.menuOptionButtonList();
      }
      this.getTypeButton(this.ruleForm.type);
    },
    //获取上级菜单
    menuOptionButtonList() {
      treeMenuShapeListV1Api({
        typeList: "1,2",
        productSource: this.productSourcePrent,
        isMenu: 1,
        addData: true,
      }).then((res) => {
        this.menuOptionList = res.result;
      });
    },
    permsButtonList() {
      menuShapeListApi({
        typeList: "5",
        productSource: this.productSourcePrent,
      }).then((res) => {
        this.permsList = res.result;
        console.log("parea", this.permsList);
      });
    },
    handleChange(value, type) {
      if (this.$refs["cascader"].getCheckedNodes()[0].data.type == 1) {
        this.ruleForm.resourcesList = "";
        return this.$message.error("不能选择目录作为资源绑定");
      }
      this.ruleForm.resources =
        this.$refs["cascader"].getCheckedNodes()[0].value;
    },
    handleChangeParent(value, type) {
      if (null != value || value != undefined) {
        if (value.length != 1) {
          this.ruleForm.parentCodeList = [];
          return this.$message.error("只能选择第一层级");
        }
      }
    },
    getPermsButton() {},
    menuStatusEnum(row, column) {
      return this.selectDictLabel(this.statusOption, row.status);
    },

    getEnumButtonList() {
      this.twoTypeList = this.$parent.twoTypeList;
      this.commonBnCssTypeList = this.$parent.commonBnCssTypeList;
      this.apiMethodList = this.$parent.apiMethodList;
      this.apiTypeList = this.$parent.apiTypeList;
    },
    changTwoTypeButton(row) {
      if (row == "commonBnTypeInit") {
        this.$set(this.ruleForm, "seniorStatus", 2);
      } else {
        this.$set(this.ruleForm, "seniorStatus", 1);
      }
    },
    getTypeButton(row) {
      //接口的时候 资源就等于全部按钮
      if (row == 4) {
        this.resourcesProps=this.buttonProps;
        menuShapeListApi({
          typeList: "3",
          productSource: this.productSourcePrent,
        }).then((res) => {
          this.resourcesList = res.result;
        });
      }
      if (row == 3) {
        this.resourcesProps=this.ifProps;
        var parems = {
          typeList: "1,2",
          productSource: this.productSourcePrent,
        };
        treeMenuShapeListV1Api(parems).then((res) => {
          this.resourcesList = res.result;
        });
        this.permsButtonList();
      }
    },
  },
};
</script>

<style  lang="less" scoped>
.demo-ruleForm {
  overflow-y: auto;
  max-height: 90%; // 设置最大高度
}

.demo-drawer__footer {
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px;
  border-top: 1px solid #ebeef5;
}
.el-form-item__label {
  width: 100px;
}
</style>